<div class="modal-body">
    <p>{{'manage.manageMembers.lblManageMembersDirections' | translate: {entityTypeName: entityTypeName} }}</p>
    <div class="container-fluid">
        <div class="row">
            <div class="col p-0">
                <h3>{{'manage.manageMembers.usersNotInEntity' | translate: {entityTypeName: entityTypeName} }}</h3>
                <input pInputText
                       id="searchUsersNotAdded"
                       type="text"
                       class="form-control"
                       (input)="usersNotInEntityTable.filterGlobal($event.target.value, 'contains')"
                       placeholder="{{'common.search' | translate}}"
                       [(ngModel)]="notInEntityTableSearchString"/>
                <p-table #usersNotInEntityTable
                         [value]="users"
                         [globalFilterFields]="['name','userName', 'locationEntityName', 'roleBundleName', 'userStateName']"
                         [paginator]="true"
                         [rows]="10"
                         [(selection)]="selectedUsersNotInEntity">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="text-center" style="width: 36px;">
                                <p-tableHeaderCheckbox inputId="allUsersNotAddedSelectBox"></p-tableHeaderCheckbox>
                            </th>
                            <th [pSortableColumn]="'name'">{{'manage.locations.name' | translate}}</th>
                            <th [pSortableColumn]="'userName'">{{'manage.locations.email' | translate}}</th>
                            <th [pSortableColumn]="'locationEntityName'">{{'cre.location' | translate}}</th>
                            <th [pSortableColumn]="'roleBundleName'">{{'manage.roles.role' | translate}}</th>
                            <th [pSortableColumn]="'userStateName'">{{'manage.locations.status' | translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-user>
                        <tr>
                            <td class="text-center">
                                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                            </td>
                            <td>
                                {{user.name}}
                            </td>
                            <td>
                                {{user.userName}}
                            </td>
                            <td>
                                <app-named-lists-popover [popoverName]="'common.multiple' | translate"
                                                         [namedLists]="user.groupedLocations"
                                                         [isPopoverNameAlways]="false"></app-named-lists-popover>
                            </td>
                            <td>
                                {{user.roleBundleName}}
                            </td>
                            <td>
                                {{user.userStateName}}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td class="text-center" [attr.colspan]="6">
                                {{'errorMessages.noRecordsFound' | translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="col-1 justify-content-center align-items-center d-flex flex-column">
                <button type="button"
                        id="addButton"
                        class="btn btn-primary d-block my-1"
                        (click)="add()">
                    <i class="fa fa-caret-right"></i>
                </button>
                <button type="button"
                        id="removeButton"
                        class="btn btn-primary d-block my-1"
                        (click)="remove()">
                    <i class="fa fa-caret-left"></i>
                </button>
            </div>
            <div class="col p-0">
                <h3>{{'manage.manageMembers.currentEntityMembers' | translate: {entityTypeName: entityTypeName} }}</h3>
                <input pInputText
                       id="searchUsersAdded"
                       type="text"
                       class="form-control"
                       (input)="usersInEntityTable.filterGlobal($event.target.value, 'contains')"
                       placeholder="{{'common.search' | translate}}"
                       [(ngModel)]="inEntityTableSearchString"/>
                <p-table #usersInEntityTable
                         [value]="usersInEntityBackup"
                         [globalFilterFields]="['name','userName', 'locationEntityName', 'roleBundleName', 'userStateName']"
                         [paginator]="true"
                         [rows]="10"
                         [(selection)]="selectedUsersInEntity">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="text-center" style="width: 3.5em">
                                <p-tableHeaderCheckbox inputId="allUsersAddedSelectBox"></p-tableHeaderCheckbox>
                            </th>
                            <th [pSortableColumn]="'name'">{{'manage.locations.name' | translate}}</th>
                            <th [pSortableColumn]="'userName'">{{'manage.locations.email' | translate}}</th>
                            <th [pSortableColumn]="'locationEntityName'">{{'cre.location' | translate}}</th>
                            <th [pSortableColumn]="'roleBundleName'">{{'manage.roles.role' | translate}}</th>
                            <th [pSortableColumn]="'userStateName'">{{'manage.locations.status' | translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-user>
                        <tr>
                            <td class="text-center">
                                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                            </td>
                            <td>
                                {{user.name}}
                            </td>
                            <td>
                                {{user.userName}}
                            </td>
                            <td>
                                <app-named-lists-popover [popoverName]="'common.multiple' | translate"
                                                         [namedLists]="user.groupedLocations"
                                                         [isPopoverNameAlways]="false"></app-named-lists-popover>
                            </td>
                            <td>
                                {{user.roleBundleName}}
                            </td>
                            <td>
                                {{user.userStateName}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button class="btn btn-secondary"
            id="cancelButton"
            type="button"
            (click)="cancel()"
            [disabled]="saving">{{'common.cancel' | translate}}</button>
    <button class="btn btn-primary"
            id="saveAndUpdateButton"
            type="button"
            (click)="saveAndUpdate()"
            [disabled]="saving">
        {{'manage.manageMembers.btnSaveAndUpdate' | translate}}
    </button>
</div>

<app-spinner [name]="'manageMembersModalSpinner'"></app-spinner>
