<div class="modal-body">
    <p [innerHTML]="body"></p>
    <input id="verificationTextInput" type="text"
           class="form-control"
           [(ngModel)]="verificationTextInput">
</div>
<div class="modal-footer">
    <button id="cancelButton"  type="button" class="btn btn-secondary" [hidden]="hideCancelButton" (click)="close(false)">{{cancelButtonText}}</button>
    <button id="confirmButton" type="button" class="btn {{confirmButtonClass}}" [disabled]="confirmButtonDisabled"
            (click)="close(true)">{{ confirmButtonText }}</button>
</div>
