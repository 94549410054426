<div class="modal-body position-relative">
    <div class="mb-3">
        <label class="form-label">{{'manage.tenants.lblBuilding' | translate}}</label>
        <select class="form-select"
                name="building"
                [(ngModel)]="building.secondaryEntityId"
                required
                [disabled]="!isNew">
            <option *ngFor="let location of locations"
                    value="{{location.entityId}}">
                {{location.entityName}}
            </option>
        </select>
    </div>
    <div class="mb-3">
        <label class="form-label" for="additionalInformation">{{'manage.tenants.lblFloor' | translate}}</label>
        <input id="additionalInformation"
               type="text"
               class="form-control"
               [(ngModel)]="building.additionalInformation"/>
    </div>
    <app-spinner [name]="'getEntityListingSpinner'"></app-spinner>
</div>

<div class="modal-footer">
    <button id="cancelButton"
            class="btn btn-secondary"
            type="button"
            (click)="cancel()"
            [disabled]="saving">{{'common.cancel' | translate}}
    </button>
    <button id="saveButton"
            class="btn btn-primary"
            type="button"
            (click)="save()"
            [disabled]="saving">
        {{'common.save' | translate}}
    </button>
</div>
