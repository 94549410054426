import { Component, Input, OnInit } from '@angular/core';
import { EntityService } from '@app/providers/entity.service';
import { SpinnerService } from '@app/providers/spinner.service';
import { Utility } from '@app/providers/utility';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Building } from '@app/shared/manage/building.model';
import { Entity } from '@app/shared/manage/entity.model';
import { entityTypeIds } from '@app/app.constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-building-modal',
	templateUrl: './building-modal.component.html',
	styleUrls: ['./building-modal.component.scss']
})
export class BuildingModalComponent implements OnInit {
	@Input() buildings: Array<Building> = [];
	@Input() building: Building = new Building();
	@Input() entity: Entity = new Entity();
	@Input() isNew: boolean = true;
	locations: Array<Entity> = [];
	entityTypeIds = entityTypeIds;
	saving: boolean = false;

	constructor(private entityService: EntityService,
		private spinnerService: SpinnerService,
		private utility: Utility,
		public translateService: TranslateService,
		private toastrService: ToastrService,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		this.buildings = this.dynamicDialogConfig.data.buildings || [];
		this.building = this.dynamicDialogConfig.data.building || new Building();
		this.entity = this.dynamicDialogConfig.data.entity;
		this.isNew = this.dynamicDialogConfig.data.isNew;
		this.dynamicDialogConfig.header = this.isNew ? this.translateService.instant('manage.tenants.lblAddBuilding') : this.translateService.instant('manage.tenants.lblEditBuilding');
	}

	ngOnInit() {
		this.getEntityListing();

		if (this.isNew) {
			this.buildNewBuilding();
		}
	}

	buildNewBuilding() {
		this.building.entityAssociationId = this.utility.getGuid();
		this.building.primaryEntityId = this.entity.entityId;
	}

	getEntityListing() {
		this.spinnerService.start('getEntityListingSpinner');

		this.entityService.getEntityListing()
			.then((res: Array<Entity>) => {
				this.locations = res.filter((e: Entity) => {
					return e.entityTypeId === this.entityTypeIds.location;
				});

				if (this.isNew) {
					// remove entities already used
					this.locations.forEach((e: Entity, i: number) => {
						if (this.buildings.findIndex((b: Building) => b.secondaryEntityId === e.entityId) > -1) {
							this.locations.splice(i, 1);
						}
					});
				}
			})
			.catch((err: any) => {
				this.dynamicDialogRef.close(false);
			})
			.then(() => {
				this.spinnerService.stop('getEntityListingSpinner');
			});
	}

	cancel() {
		this.dynamicDialogRef.close(false);
	}

	isValid(): boolean {
		let isValid: boolean = true;

		if (!this.building.secondaryEntityId) {
			this.toastrService.error(this.translateService.instant('errorMessages.msgValidateRequired'));
			isValid = false;
		}

		return isValid;
	}

	save() {
		if (!this.isValid()) {
			return;
		}

		this.saving = true;
		this.building.additionalInformationAsBase64 = this.utility.base64Encode(this.building.additionalInformation);

		this.entityService.createEntityAssociation(this.building)
			.then((res: any) => {
				this.dynamicDialogRef.close(true);
			})
			.catch((err: any) => {
				this.toastrService.error(this.translateService.instant('errorMessages.genericError'));
			})
			.then(() => {
				this.saving = false;
			});
	}
}
