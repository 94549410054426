export class PanicTriggerModel {
	messageTemplateId: string;
	messageTemplateName: string = '';
	messageTemplateNameAsBase64: string;
	locations: Array<PanicTriggerLocationModel>;
	isDefault: boolean;
	messagingTemplate: any;
}

export class PanicTriggerLocationModel {
	entityId: string;
	entityName: string = '';
	entityNameAsBase64: string;

	constructor() {
	}
}
