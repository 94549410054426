import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Entity } from '@app/shared/manage/entity.model';
import { User } from '@app/shared/user.model';
import { EntityService } from '@app/providers/entity.service';
import { GetUsersRequest } from '@app/shared/get-users-request.model';
import { NamedList } from '@app/shared/named-list.model';
import { UserService } from '@app/providers/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { SpinnerService } from '@app/providers/spinner.service';
import { Table } from 'primeng/table';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-manage-entity-members-modal',
	templateUrl: './manage-entity-members-modal.component.html',
	styleUrls: ['./manage-entity-members-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ManageEntityMembersModalComponent implements OnInit {
	@Input() entity: Entity = new Entity();
	@Input() entityTypeName: string = '';
	@Input() usersInEntity: Array<User> = [];
	// this value usersInEntityBackup is used to undo/cancel
	usersInEntityBackup: Array<User> = [];
	users: Array<User> = [];
	saving: boolean = false;
	selectedUsersNotInEntity: Array<User> = [];
	selectedUsersInEntity: Array<User> = [];
	@ViewChild('usersNotInEntityTable') usersNotInEntityTable: Table;
	@ViewChild('usersInEntityTable') usersInEntityTable: Table;
	notInEntityTableSearchString: string = '';
	inEntityTableSearchString: string = '';

	constructor(private entityService: EntityService,
		private userService: UserService,
		public translateService: TranslateService,
		private toastrService: ToastrService,
		private spinnerService: SpinnerService,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		this.entity = this.dynamicDialogConfig.data.entity;
		this.entityTypeName = this.dynamicDialogConfig.data.entityTypeName;
		this.usersInEntity = this.dynamicDialogConfig.data.usersInEntity;
		this.dynamicDialogConfig.header = this.translateService.instant('manage.manageMembers.lblManageMembersHeader', { entityTypeName: this.entityTypeName });
	}

	ngOnInit() {
		this.getAllUsers();
		this.usersInEntityBackup = _.cloneDeep(this.usersInEntity);
	}

	cancel() {
		this.dynamicDialogRef.close(false);
	}

	saveAndUpdate() {
		this.saving = true;

		this.entityService.updateUsersByEntity(this.entity, this.usersInEntityBackup)
			.then((res: any) => {
				this.usersInEntity = _.cloneDeep(this.usersInEntityBackup);
				this.toastrService.success(this.translateService.instant('manage.manageMembers.updateMembersSuccess'));
				this.dynamicDialogRef.close(true);
			})
			.catch((err: any) => {
				this.toastrService.error(this.translateService.instant('errorMessages.genericError'));
			})
			.then(() => {
				this.saving = false;
			});
	}

	getAllUsers() {
		this.spinnerService.start('manageMembersModalSpinner');

		this.userService.getUsers()
			.then((res: any) => {
				this.users = res.userItems.filter((e: User) => {
					return !this.usersInEntity.some((f: User) => {
						return e.userId === f.userId;
					});
				});

				this.users.forEach((user: User, index: number) => {
					user.name = user.firstName + ' ' + user.lastName;
					user.roleBundleName = user.roles[0].roleBundleName;

					user.groupedLocations = [];
					const primaryLocations: NamedList = new NamedList(this.translateService.instant('reports.labels.Primary Location'));
					const additionalLocations: NamedList = new NamedList(this.translateService.instant('common.additionalLocations'));

					user.locations.sort((a, b) => a.locationEntityName < b.locationEntityName ? -1 : a.locationEntityName > b.locationEntityName ? 1 : 0).forEach((location) => {
						if (location.isDefaultLocation) {
							primaryLocations.list.push(location.locationEntityName);
						}
						else {
							additionalLocations.list.push(location.locationEntityName);
						}
					});

					if (primaryLocations.list.length) {
						user.groupedLocations = user.groupedLocations.concat(primaryLocations);
					}

					if (additionalLocations.list.length) {
						user.groupedLocations = user.groupedLocations.concat(additionalLocations);
					}
				});
			})
			.catch((err: any) => {
				this.toastrService.error(this.translateService.instant('errorMessages.genericError'));
				this.dynamicDialogRef.close(false);
			})
			.then(() => {
				this.spinnerService.stop('manageMembersModalSpinner');
			});
	}

	add() {
		this.selectedUsersNotInEntity.forEach((user: User) => {
			// sanity check to make sure user is not already a member
			const i = this.usersInEntityBackup.findIndex((u: User) => {
				return user.userId === u.userId;
			});
			// index of the user in users
			const iOfUsers = this.users.findIndex((u: User) => {
				return user.userId === u.userId;
			});

			if (i < 0) {
				this.usersInEntityBackup.push(user);

				// remove the added users from selectedUsersNotInEntity
				this.users.splice(iOfUsers, 1);

				this.selectedUsersNotInEntity = [];

				// reset
				this.inEntityTableSearchString = '';
				this.notInEntityTableSearchString = '';
				this.usersInEntityTable.reset();
				this.usersNotInEntityTable.reset();
			}
		});
	}

	remove() {
		this.selectedUsersInEntity.forEach((user: User) => {
			const i = this.users.findIndex((u: User) => {
				return user.userId === u.userId;
			});
			// index of the user in usersInEntityBackup
			const iOfUsers = this.usersInEntityBackup.findIndex((u: User) => {
				return user.userId === u.userId;
			});

			if (i < 0) {
				this.users.push(user);

				// remove the users from selectedUsersInEntity
				this.usersInEntityBackup.splice(iOfUsers, 1);

				this.selectedUsersInEntity = [];

				// reset
				this.inEntityTableSearchString = '';
				this.notInEntityTableSearchString = '';
				this.usersInEntityTable.reset();
				this.usersNotInEntityTable.reset();
			}
		});
	}
}
