export class Building {
	additionalInformation: string = '';
	additionalInformationAsBase64: string = '';
	createdByFirstName: string = '';
	createdByFirstNameAsBase64: string = '';
	createdById: string = '';
	createdByLastName: string = '';
	createdByLastNameAsBase64: string = '';
	dateTimeCreated: Date = new Date();
	entityAssociationId: string = '';
	primaryEntityId: string = '';
	primaryEntityName: string = '';
	primaryEntityNameAsBase64: string = '';
	primaryEntityTypeId: string = '';
	secondaryEntityId: string = '';
	secondaryEntityName: string = '';
	secondaryEntityNameAsBase64: string = '';
	secondaryEntityTypeId: string = '';
}
