import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-confirmation-with-validation-modal',
	templateUrl: './confirmation-with-validation-modal.component.html',
	styleUrls: ['./confirmation-with-validation-modal.component.scss']
})
export class ConfirmationWithValidationModalComponent {
	@Input() body: string;
	@Input() confirmButtonText: string;
	@Input() confirmButtonClass: string;
	@Input() cancelButtonText: string;
	@Input() hideCancelButton: boolean = false;
	@Input() verificationText: string;

	public confirmButtonDisabled: boolean = true;

	constructor(public translateService: TranslateService,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		// defaults to btn-primary
		this.body = this.dynamicDialogConfig.data.body;
		this.confirmButtonClass = this.dynamicDialogConfig.data.confirmButtonClass ?? 'btn-primary';
		this.cancelButtonText = this.dynamicDialogConfig.data.cancelButtonText ?? this.translateService.instant('common.cancel');
		this.hideCancelButton = this.dynamicDialogConfig.data.hideCancelButton === true;
		this.confirmButtonText = this.dynamicDialogConfig.data.confirmButtonText;
		this.verificationText = this.dynamicDialogConfig.data.verificationText;
	}

	private _verificationTextInput: string;

	get verificationTextInput(): string {
		return null;
	}

	set verificationTextInput(inputText: string) {
		this._verificationTextInput = inputText;
		this.confirmButtonDisabled = this._verificationTextInput !== this.verificationText;
	}

	close(value: boolean) {
		this.dynamicDialogRef.close(value);
	}
}
