import { Component, Input, OnInit } from '@angular/core';
import { entityTypeIds } from '@app/app.constants';
import { Entity } from '@app/shared/incidents/entity.model';
import { PanicTriggerService } from '@app/providers/panic-trigger.service';
import { PanicTriggerLocationModel } from '@app/shared/panic-trigger.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-panic-triggers-locations-modal',
	templateUrl: './panic-triggers-locations-modal.component.html',
	styleUrls: ['./panic-triggers-locations-modal.component.scss']
})
export class PanicTriggersLocationsModalComponent implements OnInit {
	entityTypeId: string = entityTypeIds.location;
	@Input() isFilterByPortalLocations: boolean = true;
	@Input() excludeLocations: Array<string> = [];
	@Input() isNew: boolean = true;

	selectedEntities: Array<Entity> = [];
	selectedEntitiesIds: Array<string> = [];

	actionsEnabled: boolean = true;

	constructor(private panicTriggerSvc: PanicTriggerService,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig,
		private translateService: TranslateService) {
		this.isFilterByPortalLocations = this.dynamicDialogConfig?.data?.isFilterByPortalLocations;
		this.excludeLocations = this.dynamicDialogConfig?.data?.excludeLocations;
		this.isNew = this.dynamicDialogConfig?.data?.isNew;
		this.dynamicDialogConfig.header = this.translateService.instant('common.locations');
	}

	ngOnInit() {
		this.readFromPanicTrigger();
	}

	onSave() {
		this.writeToPanicTrigger();
		this.dynamicDialogRef.close({
			action: 'save'
		});
	}

	onClose() {
		this.dynamicDialogRef.close();
	}

	onSaveAndConfigureMessage() {
		this.writeToPanicTrigger();
		this.dynamicDialogRef.close({
			action: 'saveAndConfigureMessage'
		});
	}

	readFromPanicTrigger() {
		if (this.panicTriggerSvc.panicTrigger.locations
		  && this.panicTriggerSvc.panicTrigger.locations.length > 0) {
			this.selectedEntitiesIds = this.panicTriggerSvc.panicTrigger.locations.map(l => l.entityId);
			this.selectedEntities = this.panicTriggerSvc.panicTrigger.locations.map((l) => {
				return new Entity(l.entityId, l.entityName, this.entityTypeId);
			});
		}
		else {
			this.selectedEntitiesIds = [];
			this.selectedEntities = [];
		}
	}

	writeToPanicTrigger() {
		let loc: PanicTriggerLocationModel;
		this.panicTriggerSvc.panicTrigger.locations = this.selectedEntities.map((e) => {
			loc = new PanicTriggerLocationModel();
			loc.entityId = e.entityId;
			loc.entityName = e.entityName;
			return loc;
		});
	}

	// just because the control has an event
	updateEntities(ev: any) {

	}

	onNoEntitiesAvailable() {
		this.actionsEnabled = false;
	}

	onRemove(entityId: string) {
		let loc: any = this.selectedEntities.find(l => l.entityId === entityId);
		if (loc) {
			const i = this.selectedEntities.indexOf(loc);
			this.selectedEntities.splice(i, 1);
		}

		loc = this.selectedEntitiesIds.find(l => l === entityId);
		if (loc) {
			const i = this.selectedEntitiesIds.indexOf(loc);
			this.selectedEntitiesIds.splice(i, 1);
		}

		this.selectedEntities = this.selectedEntities.map(l => l);
		this.selectedEntitiesIds = this.selectedEntitiesIds.map(l => l);
	}
}
