<div class="bulkUploadModal">
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <p>{{'manage.bulkUpload.msgBulkUploadInstructions' | translate: {entityTypeName: entityTypeName} }}</p>
                    <label for="uploader-input"
                           ng2FileDrop
                           [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                           (fileOver)="fileOver($event)"
                           (onFileDrop)="fileDropped($event)"
                           [uploader]="uploader"
                           class="drop-box unformattedLabel form-label">
                        <span *ngIf="!savingInProgress">{{'manage.bulkUpload.msgDropFile' | translate}}</span>
                        <span *ngIf="savingInProgress"><i
                            class="fa fa-spinner fa-spin"></i>{{'manage.bulkUpload.msgValidatingFile' | translate}}</span>
                    </label>
                    <input id="uploader-input"
                           type="file"
                           ng2FileSelect
                           accept=".csv"
                           (change)="fileSelected($event)"
                           [uploader]="uploader"
                           style="display:none"/>
                    <div class="alert alert-danger w-100 m-0 my-3" role="alert" *ngIf="errorMessages.length > 0">
                        <ul class="mb-0">
                            <li *ngFor="let em of errorMessages;">
                                {{ em }}
                            </li>
                        </ul>
                    </div>
                    <div class="uploadSelection">
                        <div class="uploadTitle">{{'manage.bulkUpload.lblSelectedFile' | translate}}</div>
                        <div class="noFileSelected" *ngIf="!(selectedFile)">
                            {{'manage.bulkUpload.lblNoFileSelected' | translate}}
                        </div>
                        <div class="selectedFileName" *ngIf="(selectedFile)">
                            {{selectedFile.name}} - <span
                            class="fileSize">{{selectedFile.size.toString() | fileSize }}</span>
                            <span class="removeFile">
                                <a aria-label="Remove" pTooltip="{{'common.remove' | translate}}"
                                   (click)="removeSelectedFile()">
                                    <i class="fa fa-times"></i>
                                </a>
                            </span>
                        </div>
                        <div *ngIf="(fileWarning)">
                            <div class="alert alert-warning">
                                <span>{{fileWarning}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <h3>{{'manage.bulkUpload.lblNeedStartingPoint' | translate}}</h3>
                    <h4>{{'manage.bulkUpload.lblAddMultipleEntitiesHeader' | translate: {entityTypeName: entityTypeName} }}</h4>
                    <p>{{'manage.bulkUpload.lblAddMultipleEntitiesDetail' | translate: {entityTypeName: entityTypeName} }}</p>
                    <button class="btn btn-primary"
                            type="button"
                            (click)="downloadAddFile()"
                            [disabled]="downloadingAddFile">
                        {{'manage.bulkUpload.btnBlankEntitiesFile' | translate: {entityTypeName: entityTypeName} }} <i
                        *ngIf="downloadingAddFile"
                        class="fa fa-spinner fa-spin"></i>
                    </button>
                    <h4>{{'manage.bulkUpload.lblEditMultipleEntitiesHeader' | translate: {entityTypeName: entityTypeName} }}</h4>
                    <p>{{'manage.bulkUpload.lblEditMultipleEntitiesDetail' | translate}}</p>
                    <button class="btn btn-primary"
                            type="button"
                            (click)="downloadExportFile()"
                            [disabled]="downloadingExportFile">
                        {{'manage.bulkUpload.btnCurrentEntities' | translate: {entityTypeName: entityTypeName} }} <i
                        *ngIf="downloadingExportFile"
                        class="fa fa-spinner fa-spin"></i>
                    </button>
                    <h6>{{'manage.bulkUpload.lblEditMultipleEntitiesNotes' | translate: {entityTypeName: entityTypeName} }}</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" *ngIf="!savingInProgress" (click)="uploadFile()">
            <span *ngIf="!savingInProgress">{{'manage.bulkUpload.lblUpload' | translate}}</span>
            <span *ngIf="savingInProgress"><i class="fa fa-spinner fa-spin"></i></span>
        </button>
        <button class="btn btn-secondary"
                type="button"
                (click)="cancel()"
                *ngIf="!savingInProgress">{{'manage.bulkUpload.btnCancel' | translate}}
        </button>
    </div>
</div>
