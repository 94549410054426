import { Component, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { EntityService } from '@app/providers/entity.service';
import { fileCondition } from '@app/app.enum';
import { DocumentService } from '@app/providers/document.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-entity-bulk-upload-modal',
	templateUrl: './entity-bulk-upload-modal.component.html',
	styleUrls: ['./entity-bulk-upload-modal.component.scss']
})
export class EntityBulkUploadModalComponent {
	@Input() entityTypeId: string = '';
	// e.g., Location, Group, etc. SINGULAR, NOT PLURAL!!! Translations for some reason adds 's' at the end already.
	@Input() entityTypeName: string = '';
	savingInProgress: boolean = false;
	selectedFile: File;
	reader: FileReader = new FileReader();
	fileWarning: string;
	downloadingAddFile: boolean = false;
	downloadingExportFile: boolean = false;
	uploader: FileUploader = new FileUploader({ url: null });
	hasBaseDropZoneOver: boolean = false;
	errorMessages: Array<string> = [];

	constructor(private entityService: EntityService,
		private toastrService: ToastrService,
		public translateService: TranslateService,
		private documentService: DocumentService,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		this.entityTypeId = this.dynamicDialogConfig.data.entityTypeId;
		this.entityTypeName = this.dynamicDialogConfig.data.entityTypeName;
		this.dynamicDialogConfig.header = this.translateService.instant('manage.bulkUpload.lblBulkUpload');
	}

	fileOver(e: boolean): void {
		this.hasBaseDropZoneOver = e;
	}

	fileDropped(e: any): void {
		this.processFile(e[0]);
	}

	fileSelected(e: any): void {
		this.processFile(e.target.files[0]);
	}

	processFile(file) {
		this.removeSelectedFile();
		this.errorMessages = [];
		this.fileWarning = '';

		this.documentService.checkFileSizeAndMaliciousTypes(file, true, ['csv']).then((_) => {
			if (file.name.toLowerCase().includes('test')) {
				this.fileWarning = this.translateService.instant('users.bulkUpload.msgTestBulkUploadFile');
				console.log('waos');
			}
			this.selectedFile = file;
			this.reader.readAsDataURL(file);
		}).catch((err) => {
			if (err.reason === fileCondition.notSupported) {
				this.errorMessages.push(
					this.translateService.instant('users.bulkUpload.errorInvalidBulkFileType')
				);
			}
			else {
				this.errorMessages.push(err.message);
			}
		});
	}

	removeSelectedFile() {
		this.selectedFile = null;
	}

	downloadAddFile() {
		this.downloadingAddFile = true;
		this.entityService.exportHeadersForEntityCsvFile(this.entityTypeId).then((response: Blob) => {
			saveAs(response, this.translateService.instant('manage.bulkUpload.fileName', { entityTypeName: this.entityTypeName }));

			this.dynamicDialogRef.close('cancel');
			this.toastrService.success(this.translateService.instant('common.msgrExportSuccessful', { targetType: this.entityTypeName }));
		}).catch((_) => {
			this.toastrService.error(this.translateService.instant('common.errorExportFailed', { targetType: this.entityTypeName }));
		}).then((_) => {
			this.downloadingAddFile = false;
		});
	}

	downloadExportFile() {
		this.downloadingExportFile = true;
		this.entityService.exportEntityCsvFile(this.entityTypeId).then((response: any) => {
			saveAs(response, this.translateService.instant('manage.bulkUpload.fileName', { entityTypeName: this.entityTypeName }));
			this.dynamicDialogRef.close('cancel');
			this.toastrService.success(this.translateService.instant('common.msgrExportSuccessful', { targetType: this.entityTypeName }));
		}).catch((_) => {
			this.toastrService.error(this.translateService.instant('common.errorExportFailed', { targetType: this.entityTypeName }));
		}).then((_) => {
			this.downloadingExportFile = false;
		});
	}

	uploadFile() {
		this.errorMessages = [];

		if (!this.selectedFile) {
			this.toastrService.error(this.translateService.instant('users.bulkUpload.lblNoFileSelected'));
			return;
		}

		this.savingInProgress = true;
		const dataUrl = this.reader.result as string;
		this.entityService.uploadBulkEntityFile(this.entityTypeId, dataUrl.substring(dataUrl.indexOf(',') + 1), this.selectedFile.name).then((result: any) => {
			if (result.bulkErrorList && result.bulkErrorList.length > 0) {
				result.bulkErrorList.forEach((errorItem) => {
					this.errorMessages.push(errorItem);
				});
			}
			else {
				this.toastrService.success(this.translateService.instant('manage.bulkUpload.msgUploadUsersSuccess'));
				this.dynamicDialogRef.close();
			}
		}).catch((error) => {
			if (error.bulkErrorList) {
				error.bulkErrorList.forEach((errorItem) => {
					this.errorMessages.push(errorItem);
				});
			}
			else {
				this.toastrService.error(error.errorMessage);
			}
		}).then((_) => {
			this.savingInProgress = false;
		});
	}

	cancel() {
		this.dynamicDialogRef.close('cancel');
	}
}
