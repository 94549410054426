<div class="panicTriggerLocationsWrapper">
    <div *ngIf="actionsEnabled">
        <label class="my-3 form-label"><i class="fa fa-map-marker"></i> {{'common.selected' | translate}}</label>
        <div class="row mb-3 me-1" style="max-height: 100px; overflow-y: auto">
            <div class="col-md-3 col-sm-12 col-xs-12" *ngFor="let loc of selectedEntities">
                <div class="selection d-block text-truncate">
                    {{loc.entityName}}
                </div>
            </div>
        </div>
    </div>
    <div class="text-danger text-center" *ngIf="!actionsEnabled">
        {{'panicTriggers.locationsNotAvailable' | translate}}
    </div>
    <div *ngIf="actionsEnabled">
        <app-filter-entity
            [entityTypeId]="entityTypeId"
            (entitySelectedEvent)="updateEntities($event)"
            [selectedEntityIds]="selectedEntitiesIds"
            [selectedEntities]="selectedEntities"
            [isFilterByPortalLocations]="isFilterByPortalLocations"
            [excludeEntities]="excludeLocations"
            [showViewSelectionsLink]="false"
            (noEntitiesAvailable)="onNoEntitiesAvailable()">
        </app-filter-entity>
    </div>
    <div class="text-end">
        <button id="cancelButton" class="btn btn-secondary"
                (click)="onClose()">{{'common.cancel' | translate}}
        </button>
        <button class="btn btn-success"
                *ngIf="!isNew && actionsEnabled"
                [disabled]="!selectedEntities || selectedEntities.length === 0"
                (click)="onSave()">{{'common.save' | translate}}
        </button>
        <button class="btn btn-primary"
                *ngIf="actionsEnabled"
                [disabled]="!selectedEntities || selectedEntities.length === 0"
                (click)="onSaveAndConfigureMessage()">{{(isNew ? 'panicTriggers.btnConfigureMessage' : 'panicTriggers.btnSaveAndConfigureMessage') | translate}}
        </button>
    </div>
</div>
